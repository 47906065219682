<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			title="设置商品领取地址">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="100px">
					<el-form-item label="负责人电话">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.isc_phone" placeholder="请输入负责人电话">
						</el-input>
					</el-form-item>
					<el-form-item label="领取地址">
						<span class="xx">*</span>
						<el-input type="textarea" clearable v-model="ruleForm.isc_address" placeholder="请输入领取地址">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/village";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					isc_address: '',
					isc_phone: "",
					id: ''
				}

			}
		},
		methods: {
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.$store.state.villageId
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.isc_phone === '') {
					ElMessage({
						message: '请输入负责人电话',
						type: 'warning',
					})
				} else if (this.ruleForm.isc_address === '') {
					ElMessage({
						message: '请输入领取地址',
						type: 'warning',
					})
				} else {
					this.$http.post(api.edit, this.ruleForm)
						.then((res) => {
							if (res.code == 200) {
								ElMessage({
									type: 'success',
									message: '提交成功',
								})
								this.$emit('SubmitForm')
								this.outerVisible = false
							}
						})
				}
			},

			// 显示隐藏
			show() {
				this.getinfo()
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>
