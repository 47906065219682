import request from './request'

const api = {
	// 获取列表
	lists: '/isc/Product/list',
	// 添加
	add: '/isc/Product/create',
	// 删除
	dele: '/isc/Product/delete',
	// 编辑
	edit: '/isc/Product/update',
	// 详情
	info: '/isc/Product/read',
}
export {
	request,
	api
}
